import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  VStack,
  useColorModeValue,
  IconButton,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Text,
  Badge
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import DataTablePagination from '../../components/base/DataTablePagination';
import DataTable from '../../components/base/DataTableTranslate';
import { format } from 'date-fns';
import { get } from '../../../../api/request';
import { LANGUAGES } from './translations';

const createColumnMap = (currentLanguage) => ({
//   index: {
//     label: '#',
//     sortable: false,
//     width: '5%',
//     align: 'center',
//     formatter: (_, index, pagination) => (pagination.currentPage - 1) * pagination.itemsPerPage + index + 1
//   },
  redemption_code: {
    label: 'Redemption Code',
    sortField: 'redemption_code',
    width: '15%',
    minWidth: '150px',
    align: 'center',
    formatter: (redemption) => {
      return (
        <Text fontFamily="mono" fontWeight="medium">
          {redemption.redemption_code}
        </Text>
      );
    }
  },
  member_info: {
    label: 'Member',
    sortField: 'member_name',
    width: '20%',
    minWidth: '200px',
    align: 'center',
    formatter: (redemption) => {
      return (
        <VStack spacing={1} align="center">
          <Text fontWeight="medium">{redemption.member_info}</Text>
        </VStack>
      );
    }
  },
  email: {
    label: 'Email',
    sortField: 'email',
    width: '20%',
    minWidth: '200px',
    align: 'center',
    formatter: (redemption) => {
      return (
        <VStack spacing={1} align="center">
          <Text fontWeight="medium">{redemption.email}</Text>
        </VStack>
      );
    }
  },
  status: {
    label: 'Status',
    sortField: 'status',
    width: '10%',
    minWidth: '150px',
    align: 'center',
    formatter: (redemption) => {
      return (
        <Badge 
          colorScheme={redemption.status === 'redeemed' ? 'green' : 'gray'} 
          px={3} 
          py={1} 
          borderRadius="full"
        >
          {redemption.status}
        </Badge>
      );
    }
  },
  redeemed_at: {
    label: 'Redeemed At',
    sortField: 'redeemed_at',
    width: '15%',
    minWidth: '200px',
    align: 'center',
    formatter: (redemption) => {
      if (!redemption.redeemed_at) return '-';
      try {
        const date = new Date(redemption.redeemed_at);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      } catch {
        return '-';
      }
    }
  }
});

const RedemptionListPage = () => {
  const [redemptions, setRedemptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ field: 'redeemed_at', direction: 'desc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
  const [totalCount, setTotalCount] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });

  const columnMap = useMemo(() => createColumnMap(currentLanguage), [currentLanguage]);

  const processDataForTable = useCallback((data) => {
    let globalIndex = 0;
    
    return data.map((item) => {
      const { userProfile, redemptions } = item;
      
      return redemptions.map(redemption => {
        const processedData = {};
        Object.keys(columnMap).forEach(key => {
          if (key === 'index') {
            processedData[key] = columnMap[key].formatter?.(
              null,
              globalIndex++,
              pagination,
              currentLanguage
            );
          } else {
            const formattedValue = columnMap[key].formatter?.(
              {
                ...redemption,
                member_info: `${userProfile.given_name} ${userProfile.surname}`,
                email: userProfile.email
              },
              globalIndex,
              pagination,
              currentLanguage
            ) ?? redemption[key];
            processedData[key] = formattedValue;
          }
        });
        processedData._id = redemption.id;
        return processedData;
      });
    }).flat();
  }, [columnMap, pagination, currentLanguage]);

  const sortData = useCallback((data) => {
    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.field];
      const bValue = b[sortConfig.field];
      
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;
      
      if (['redeemed_at', 'used_at', 'created_at', 'updated_at'].includes(sortConfig.field)) {
        return sortConfig.direction === 'asc' 
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }
      
      const comparison = aValue > bValue ? 1 : -1;
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    });
  }, [sortConfig]);

  const fetchRedemptions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const params = {
        page_number: pagination.currentPage,
        page_size: pagination.itemsPerPage
      };
      
      const response = await get('coupon_redemption_list', params);
      
      if (response?.data) {
        const processedData = processDataForTable(response.data);
        console.log('processedData', processedData);
        setRedemptions(processedData);
        setTotalCount(processedData.length);
      }
    } catch (error) {
      console.error('Error fetching redemptions:', error);
      setError(error.message || 'Failed to fetch redemption data');
    } finally {
      setIsLoading(false);
    }
  }, [processDataForTable, pagination]);

  useEffect(() => {
    fetchRedemptions();
  }, [fetchRedemptions]);

  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination({ currentPage: 1, itemsPerPage: newItemsPerPage });
  }, []);

  const paginatedData = useMemo(() => {
    const sortedData = sortData(redemptions);
    const start = (pagination.currentPage - 1) * pagination.itemsPerPage;
    const end = start + pagination.itemsPerPage;
    return sortedData.slice(start, end);
  }, [redemptions, pagination, sortData]);

  const totalPages = Math.ceil(totalCount / pagination.itemsPerPage);
  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Box bg={bgColor} p={[3, 4, 5]} width="100%">
        <VStack spacing={[3, 4, 5]} align="stretch">
          <Flex
            direction={["column", "row"]}
            justifyContent="space-between"
            alignItems={["flex-start", "center"]}
            wrap="wrap"
            gap={4}
          >
            <Text fontSize="lg" fontWeight="bold">
              Redemption Records
            </Text>

            <HStack spacing={4} mt={[0, 0]} wrap="wrap">
              <IconButton
                variant="action"
                ml="auto"
                icon={<RepeatIcon />}
                aria-label="Refresh"
                onClick={fetchRedemptions}
              />
            </HStack>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : (
            <DataTable
              data={paginatedData}
              columnMap={columnMap}
              showActions={false}
              sortField={sortConfig.field}
              sortDirection={sortConfig.direction}
              onSort={handleSort}
              onRefresh={fetchRedemptions}
            />
          )}

          {totalCount > pagination.itemsPerPage && (
            <DataTablePagination
              currentPage={pagination.currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              itemsPerPage={pagination.itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              itemsPerPageOptions={[5, 10, 20, 50]}
            />
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default RedemptionListPage;