import React , { useRef } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Image, Switch, HStack, Tooltip, Flex, useColorModeValue,Text} from '@chakra-ui/react';
import { EditIcon, DeleteIcon,ViewIcon} from '@chakra-ui/icons';
const formatDate = (value) => {
  
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;

};

const CustomMemberTable = ({
  data,
  tableHeaders,
  handleView,
  handleEdit,
  handleDelete,
  handleToggleActive,
  keyMappings = {}, // Key mappings for display names
  isViewDisabled,
  isEditDisabled, // New prop
  isDeleteDisabled,
  currentPage, // Accept currentPage as a prop
  itemsPerPage, // Accept
  showActions = true,
  handleSort, // 接收 handleSort
  hideView,
  hideEdit,
  hideDelete,
  tableName
}) => {
  const tableRef = useRef(null);
  const tableSize = 'md';


  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const theadBgColor = useColorModeValue('gray.50', 'gray.700');
  const thHoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

const getColumnWidth = (index) => {
    if (index === 0) return '10%';
    const remainingWidth = 90;
    const totalColumns = tableHeaders.length + (showActions ? 1 : 0);
    return `${remainingWidth / totalColumns}%`;
  };

  const renderTh = (key) => {
    const keys = key.split('.');
    var name = (keyMappings[key] || key)
    if (keys.length > 1) {
      const last_key = keys[keys.length - 1]
      var name = (keyMappings[last_key] || last_key)
    }
    return  capitalizeFirstLetter(name);
    
  };

  const renderCellValue = (value) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return JSON.stringify(value);
    }
    return value;
  };

  const renderCellKey = (key, item) => {

    const keys = key.split('.');
    var key_name = key
    let value = item[key_name];

    if (key_name === 'mandatory_update_version') {
      return renderCellValue(value);
    }

    if (keys.length === 3) {

      // console.log('keys',keys)
      let indexTable = keys[0]
      if (item[indexTable].length > 0) {
        let index = keys[1]
        let indexKey = keys[2]
        value = item[indexTable][index][indexKey]
      }

    } else if (keys.length === 2) {

      let indexTable = keys[0]
      let indexKey = keys[1]
      // console.log('keys',item[indexTable])
      value = item[indexTable][indexKey]

    }

    if (value === null || value === undefined) return '';
    if (/image_url|image/i.test(key)) {
      // console.log('item[key]', key)
      return (
        <Box maxWidth="100px">
          <Image src={value} objectFit="contain" alt=" " />
        </Box>
      );
    } else if (/card_face/i.test(key)) {
      return (
        <Box maxWidth="80px" mt={3}>
          <Image src={value} objectFit="contain" alt=" " />
        </Box>
      );

    } else if (key === 'is_active') {
      return (
        <Switch
          isChecked={item[key]}
          onChange={() => handleToggleActive(item)}
          size="sm"
        />
      )
    } else if (['created_at', 'updated_at', 'sent_at', 'scheduled_at'].indexOf(key_name) > -1) {
      return (renderCellValue(formatDate(value)))
    } else if (key_name.includes('date')) {
      return (renderCellValue(formatDate(value)))
    } else if (key_name.includes('tier')) {
      value = value?.charAt(0).toUpperCase() + value?.slice(1)
      return (renderCellValue(value))
    } else if (key_name.includes('language')) {
      if (value === 'zh-Hant') {
        value = 'Traditional Chinese'
      } else if (value === 'zh-Hans') {
        value = 'Simplified Chinese'
      } else {
        value = 'English'
      }
      // console.log('key',key,item[key])
      return (renderCellValue(value))
    } else if (key_name.includes('platform')) {
      if (value === 'ios') {
        value = 'iOS'
      } else if (value === 'android') {
        value = 'Android'
      }
      // console.log('key',key,item[key])
      return (renderCellValue(value))
    } else if (key_name.includes('_color')) {
      return <Box height="20px" width="20px" bg={value} borderRadius="sm" />;
    } else {
      return (renderCellValue(value))
    }

  };

  const renderCellContent = (key, item) => {
    const value = renderCellKey(key, item);
    if (key === 'detail') { 
      const minWidth = value.length <= 10 ? '80px' : '120px';
      const maxWidth = '200px';
      return (
        <Box
        minWidth={minWidth}
        maxWidth={maxWidth}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="normal"
          display="-webkit-box"
          style={{
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </Box>
      );
    }
    if (typeof value === 'string') {
      const minWidth = value.length <= 10 ? '80px' : '120px';
      const maxWidth = '200px';

      const content = (
        <Box
          minWidth={minWidth}
          maxWidth={maxWidth}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {value}
        </Box>
      );

      if (value.length > 10) {
        // console.log('value', value.length)
        return (
          <Tooltip label={value}>
            <Box
              maxWidth="300px"
              minWidth="120px"
              maxHeight="200px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="normal"
              display="-webkit-box"
              style={{
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      }

      return content;
    }

    return value;
  };

  return (
    <Box borderWidth="1px" borderRadius="md" boxShadow="lg" overflow="hidden"  overflowX="auto"
    overflowY="auto"
     maxW="100%"
    maxH="900px">
    <Table
      width="100%"
      ref={tableRef}
      variant="simple"
      size={tableSize}
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      minWidth="800px"
    >
      <Thead>
        <Tr>
          <Th
            textTransform="none"
            textAlign="center"
            bg={theadBgColor}
            _hover={{ bg: thHoverBgColor }}
            fontSize ="md"
          >
            #
          </Th>
          {tableHeaders.map((key, index) => (
            <Th
              key={key}
              textTransform="none"
              textAlign="left"
              bg={theadBgColor}
              cursor="pointer"
              onClick={() => handleSort(key)}
              _hover={{ bg: thHoverBgColor }}
              width={getColumnWidth(index)}
              py={7}  
              fontSize ="md"
            >
              <Flex align="center">
                <Text whiteSpace="nowrap">{key}</Text>
              </Flex>
            </Th>
          ))}
          {showActions && (
            <Th textAlign="center" bg={theadBgColor} _hover={{ bg: thHoverBgColor }}>
              Actions
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item, rowIndex) => (
          <Tr key={rowIndex} _hover={{ bg: hoverBgColor }}>
            <Td textAlign="center">{(currentPage - 1) * itemsPerPage + rowIndex + 1}</Td>
            {tableHeaders.map((key, colIndex) => (
              <Td key={key} textAlign="left" width={getColumnWidth(colIndex)}>
                {item[key]}
              </Td>
            ))}
            {showActions && (
              <Td textAlign="center">
                <HStack justify="center" spacing={2}>
                {!hideView && (
                <IconButton
                        onClick={() => handleView(item, tableName)}
                        icon={<ViewIcon/>}
                        colorScheme="purple"
                        aria-label="View"
                        size="sm"
                        isDisabled={isViewDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                )}
                {!hideEdit && (      
                <IconButton
                        onClick={() => handleEdit(item)}
                        icon={<EditIcon />}
                        colorScheme="blue"
                        aria-label="Edit"
                        size="sm"
                        isDisabled={isEditDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                )}
                 {!hideDelete && (
                  <IconButton
                        onClick={() => handleDelete(item)}
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        aria-label="Delete"
                        size="sm"
                        isDisabled={isDeleteDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                    )}
                </HStack>
              </Td>
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
  );
};

export default CustomMemberTable;
