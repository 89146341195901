import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text,
  useBreakpointValue,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import DragNDrop from '../../components/base/DragNDrop';
import { i18n, LANGUAGES } from './translations';
import { post } from '../../../../api/request';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import WProofreader from '@webspellchecker/wproofreader-sdk-js';

const INITIAL_FORM_STATE = {
  points_required: '',
  total_quantity: '',
  remaining_quantity: '',
  effective_start_date: '',
  effective_end_date: '',
  redemption_start_date: '',
  redemption_end_date: '',
  translations: Object.values(LANGUAGES).map(lang => ({
    language: lang,
    caption: '',
    detail: '',
    tnc: '',
    image_url: '',
  }))
};

const AddEditCouponModal = ({ isOpen, onClose, onComplete, initialData = null }) => {
  const modalSize = useBreakpointValue({
    base: "90%",
    md: "90%",
    lg: "85%",
    xl: "80%"
  });

  const modalMaxWidth = useBreakpointValue({
    base: "90%",
    md: "800px",
    lg: "1000px",
    xl: "1200px"
  });

  const contentPadding = useBreakpointValue({
    base: "1rem",
    md: "1.5rem",
    lg: "2rem"
  });

  const submitButtonSize = useBreakpointValue({
    base: "80%",
    md: "70%",
    lg: "60%",
    xl: "50%"
  });

  // In the component:
const isLargerThan768 = useBreakpointValue({ base: false, md: true });
const editorMinHeight = useBreakpointValue({ base: '200px', md: '300px' });

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);

  const showAlert = (status, title, description) => {
    setAlertInfo({ status, title, description });
    setTimeout(() => setAlertInfo(null), 3000);
  };

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setFormData({
          id: initialData.id,
          points_required: initialData.points_required,
          total_quantity: initialData.total_quantity,
          remaining_quantity: initialData.remaining_quantity,
          effective_start_date: initialData.effective_start_date.split('T')[0],
          effective_end_date: initialData.effective_end_date.split('T')[0],
          redemption_start_date: initialData.redemption_start_date.split('T')[0],
          redemption_end_date: initialData.redemption_end_date.split('T')[0],
          translations: Object.values(LANGUAGES).map(lang => {
            const translation = initialData.translations.find(t => t.language === lang) || {};
            return {
              id: translation.id,
              language: lang,
              caption: translation.caption || '',
              detail: translation.detail || '',
              tnc: translation.tnc || '',
              image_url: translation.image_url || '',
            };
          })
        });
      } else {
        setFormData(INITIAL_FORM_STATE);
      }
      setActiveLanguageTab(0);
      setErrors({});
      setAlertInfo(null);
    }
  }, [isOpen, initialData]);

  const handleBasicInfoChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleTranslationChange = (language, field, value) => {
    setFormData(prev => ({
      ...prev,
      translations: prev.translations.map(translation =>
        translation.language === language
          ? { ...translation, [field]: value }
          : translation
      )
    }));
    setErrors(prev => ({ ...prev, [`${language}_${field}`]: '' }));
  };

  const handleImageUpload = async (language, file) => {
    if (!file) return;

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;

    if (!validImageTypes.includes(file.type)) {
      showAlert('error', 'Invalid File Type', 'Please upload a valid image file (JPEG, PNG, or GIF)');
      return;
    }

    if (file.size > maxSize) {
      showAlert('error', 'File Too Large', 'Image size should be less than 5MB');
      return;
    }

    try {
      const image_url = await global.genericapi.uploadImageAndGetPublicUrl('coupons', file);
      handleTranslationChange(language, 'image_url', image_url);
      showAlert('success', 'Success', 'Image uploaded successfully');
    } catch (error) {
      showAlert('error', 'Upload Failed', error.message);
    }
  };

  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    const file = files[0];
    
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;
  
    if (!validImageTypes.includes(file.type)) {
      showAlert('error', 'Invalid File Type', 'Please upload a valid image file (JPEG, PNG, or GIF)');
      return false;
    }
  
    if (file.size > maxSize) {
      showAlert('error', 'File Too Large', 'Image size should be less than 5MB');
      return false;
    }
  
    try {
      const image_url = await global.genericapi.uploadImageAndGetPublicUrl('coupons', file);
      const response = {
        result: [
          {
            url: image_url,
            name: file.name,
            size: file.size
          }
        ]
      };
      uploadHandler(response);
      showAlert('success', 'Success', 'Image uploaded successfully');
    } catch (error) {
      showAlert('error', 'Upload Failed', error.message);
      return false;
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Basic validation
    if (!formData.points_required) newErrors.points_required = 'Required';
    if (!formData.total_quantity) newErrors.total_quantity = 'Required';
    if (!formData.effective_start_date) newErrors.effective_start_date = 'Required';
    if (!formData.effective_end_date) newErrors.effective_end_date = 'Required';
    if (!formData.redemption_start_date) newErrors.redemption_start_date = 'Required';
    if (!formData.redemption_end_date) newErrors.redemption_end_date = 'Required';

    // Translation validation
    formData.translations.forEach(translation => {
      if (!translation.caption?.trim()) {
        newErrors[`${translation.language}_caption`] = 'Required';
      }
      if (!translation.detail?.trim()) {
        newErrors[`${translation.language}_detail`] = 'Required';
      }
      if (!translation.tnc?.trim()) {
        newErrors[`${translation.language}_tnc`] = 'Required';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      showAlert('error', 'Validation Error', 'Please check all required fields');
      return;
    }

    setIsLoading(true);

    try {

      if (initialData) {
        const jsonData = {
          'id': parseInt(initialData.id),
          'points_required': parseInt(formData.points_required),
          'total_quantity': parseInt(formData.total_quantity),
          'effective_start_date': formData.effective_start_date,
          'effective_end_date': formData.effective_end_date,
          'redemption_start_date': formData.redemption_start_date,
          'redemption_end_date': formData.redemption_end_date,
          'translations': formData.translations.map(translation => ({
            'language': translation.language,
            'caption': translation.caption,
            'detail': translation.detail,
            'tnc': translation.tnc,
            'image_url': translation.image_url
          }))
        };
        await post('update_coupon', jsonData);
        showAlert('success', 'Success', 'Coupon updated successfully');
      } else {
        const jsonData = {
          'points_required': parseInt(formData.points_required),
          'total_quantity': parseInt(formData.total_quantity),
          'effective_start_date': formData.effective_start_date,
          'effective_end_date': formData.effective_end_date,
          'redemption_start_date': formData.redemption_start_date,
          'redemption_end_date': formData.redemption_end_date,
          'translations': formData.translations.map(translation => ({
            'language': translation.language,
            'caption': translation.caption,
            'detail': translation.detail,
            'tnc': translation.tnc,
            'image_url': translation.image_url
          }))
        };
        await post('create_coupon', jsonData);
        showAlert('success', 'Success', 'Coupon created successfully');
      }

      onClose();
      onComplete();
    } catch (error) {
      console.error('Error in coupon operation:', error);
      showAlert('error', 'Operation Failed', error.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={modalMaxWidth}
        w={modalSize}
        mx="auto"
        my={{ base: 0, md: 4 }}
        h={{ base: "100vh", md: "auto" }}
      >
        <ModalHeader>
          {initialData ? 'Edit Coupon' : 'Create New Coupon'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alertInfo && (
            <Alert status={alertInfo.status} mb={4}>
              <AlertIcon />
              <AlertTitle mr={2}>{alertInfo.title}</AlertTitle>
              <AlertDescription>{alertInfo.description}</AlertDescription>
            </Alert>
          )}

          <form id="coupon-form" onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              <Box px={contentPadding}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  Basic Information
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <FormControl isInvalid={errors.points_required}>
                    <FormLabel>Points Required</FormLabel>
                    <NumberInput
                      value={formData.points_required}
                      onChange={(value) => handleBasicInfoChange('points_required', value)}
                      min={0}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    {errors.points_required && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.points_required}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={errors.total_quantity}>
                    <FormLabel>Total Quantity</FormLabel>
                    <NumberInput
                      value={formData.total_quantity}
                      onChange={(value) => handleBasicInfoChange('total_quantity', value)}
                      min={0}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    {errors.total_quantity && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.total_quantity}
                      </Text>
                    )}
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
                  <FormControl isInvalid={errors.effective_start_date}>
                    <FormLabel>Effective Start Date</FormLabel>
                    <Input
                      type="date"
                      value={formData.effective_start_date}
                      onChange={(e) => handleBasicInfoChange('effective_start_date', e.target.value)}
                    />
                    {errors.effective_start_date && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.effective_start_date}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={errors.effective_end_date}>
                    <FormLabel>Effective End Date</FormLabel>
                    <Input
                      type="date"
                      value={formData.effective_end_date}
                      onChange={(e) => handleBasicInfoChange('effective_end_date', e.target.value)}
                    />
                    {errors.effective_end_date && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.effective_end_date}
                      </Text>
                    )}
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
                  <FormControl isInvalid={errors.redemption_start_date}>
                    <FormLabel>Redemption Start Date</FormLabel>
                    <Input
                      type="date"
                      value={formData.redemption_start_date}
                      onChange={(e) => handleBasicInfoChange('redemption_start_date', e.target.value)}
                    />
                    {errors.redemption_start_date && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.redemption_start_date}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={errors.redemption_end_date}>
                    <FormLabel>Redemption End Date</FormLabel>
                    <Input
                      type="date"
                      value={formData.redemption_end_date}
                      onChange={(e) => handleBasicInfoChange('redemption_end_date', e.target.value)}
                    />
                    {errors.redemption_end_date && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.redemption_end_date}
                      </Text>
                    )}
                  </FormControl>
                </Grid>
              </Box>

              <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
                <TabList px={contentPadding}>
                  {Object.values(LANGUAGES).map(code => (
                    <Tab key={code}>
                      {i18n.getLanguageLabel(code)}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                {Object.values(LANGUAGES).map(langCode => (
                    <TabPanel key={langCode}>
                      <VStack spacing={4} align="stretch" px={contentPadding}>
                        <FormControl isInvalid={errors[`${langCode}_caption`]}>
                          <FormLabel>Caption</FormLabel>
                          <Input
                            value={formData.translations.find(t => t.language === langCode)?.caption || ''}
                            onChange={(e) => handleTranslationChange(langCode, 'caption', e.target.value)}
                            placeholder="Enter caption"
                          />
                          {errors[`${langCode}_caption`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${langCode}_caption`]}
                            </Text>
                          )}
                        </FormControl>

                        <FormControl>
                          <FormLabel>Coupon Image</FormLabel>
                          <DragNDrop
                            onFileUpload={(file) => handleImageUpload(langCode, file)}
                            currentImage={formData.translations.find(t => t.language === langCode)?.image_url}
                            dragText="Drag image here or click to upload"
                          />
                        </FormControl>

                      <FormControl isInvalid={errors[`${langCode}_detail`]}>
                        <FormLabel>Detail</FormLabel>
                        <Textarea
                          value={formData.translations.find(t => t.language === langCode)?.detail || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'detail', e.target.value)}
                          placeholder="Enter coupon details"
                          rows={3}
                        />
                        {errors[`${langCode}_detail`] && (
                          <Text color="red.500" fontSize="sm" mt={1}>
                            {errors[`${langCode}_detail`]}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl isInvalid={errors[`${langCode}_tnc`]}>
                        <FormLabel>Terms and Conditions</FormLabel>
                        <SunEditor
                          setContents={formData.translations.find(t => t.language === langCode)?.tnc || ''}
                          onChange={(value) => handleTranslationChange(langCode, 'tnc', value)}
                          onImageUploadBefore={handleImageUploadBefore}
                          setOptions={{
                            buttonList: [
                              ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image', 'video', 'audio'],
                              ['fullScreen', 'showBlocks', 'codeView'],
                              isLargerThan768 ? ['preview', 'print'] : [],
                              ['save', 'template'],
                            ],
                            minHeight: editorMinHeight,
                            placeholder: 'Enter terms and conditions',
                            // Enable native spellcheck
                            formats: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                            attributesWhitelist: {
                              ...SunEditor.attributesWhitelist,
                              'spellcheck': 'true',
                            }
                          }}
                        />
                        {errors[`${langCode}_tnc`] && (
                          <Text color="red.500" fontSize="sm" mt={1}>
                            {errors[`${langCode}_tnc`]}
                          </Text>
                        )}
                      </FormControl>

                      </VStack>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Center w="full">
            <Button
              colorScheme="blue"
              size="lg"
              type="submit"
              form="coupon-form"
              isLoading={isLoading}
              loadingText={initialData ? 'Updating' : 'Creating'}
              width={submitButtonSize}
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              fontSize="md"
              fontWeight="bold"
              boxShadow="lg"
              borderRadius="md"
              transition="all 0.2s"
            >
              {initialData ? 'Update' : 'Create'}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddEditCouponModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.number,
    points_required: PropTypes.number,
    total_quantity: PropTypes.number,
    remaining_quantity: PropTypes.number,
    effective_start_date: PropTypes.string,
    effective_end_date: PropTypes.string,
    redemption_start_date: PropTypes.string,
    redemption_end_date: PropTypes.string,
    translations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      language: PropTypes.string.isRequired,
      caption: PropTypes.string,
      detail: PropTypes.string,
      tnc: PropTypes.string,
      image_url: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    })),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

AddEditCouponModal.defaultProps = {
  initialData: null,
};

export default AddEditCouponModal;