import {
  Box,
  Button,
  Alert,
  AlertIcon,
  Text,
  Image,
  IconButton,

} from "@chakra-ui/react";
import { DeleteIcon } from '@chakra-ui/icons';
import * as FormFields from "./FormFields";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Tabs, TabList, TabPanels, Tab, TabPanel,FormControl,FormLabel} from '@chakra-ui/react';
const InlineEditBox = ({
    initialData,
    onSave,
    dataStructure,
    tableName,
    isEdit = false,
    isMultiLanguageSupported = false,
  }) => {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [activeLanguageTab, setActiveLanguageTab] = useState(0);
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(initialData?.image_url || null);
  
    const supportedLanguage = ["en", "zh-Hant", "zh-Hans"];
    const LANGUAGE_LABELS = {
        en: 'English',
        'zh-Hans': 'Traditional Chinese',
        'zh-Hant': 'Simplified Chinese',
      };
    const initializeTranslations = () => {
      return supportedLanguage.reduce((acc, language) => {
        acc[language] = Object.keys(dataStructure).reduce((fieldAcc, key) => {
          fieldAcc[key] = ""; // 每个字段初始化为空字符串
          return fieldAcc;
        }, {});
        return acc;
      }, {});
    };
  
    const [data, setData] = useState({
      ...initialData,
      translations: initialData?.translations || initializeTranslations(),
    });
  
    const handleChange = useCallback((field, value, language = null) => {
      setData((prevData) => {
        if (language) {
          const updatedTranslations = { ...prevData.translations };
          updatedTranslations[language] = {
            ...(updatedTranslations[language] || {}),
            [field]: value,
          };
          return { ...prevData, translations: updatedTranslations };
        } else {
          return { ...prevData, [field]: value };
        }
      });
    }, []);
  
    const handleImageUpload = (url, language = null) => {
      if (isMultiLanguageSupported && language) {
        handleChange("image_url", url, language);
      } else {
        handleChange("image_url", url);
      }
    };
  
    const handleImageDelete = (language = null) => {
      if (isMultiLanguageSupported && language) {
        handleChange("image_url", "", language);
      } else {
        handleChange("image_url", "");
      }
    };
  
    const validateFields = useCallback(() => {
      const newErrors = {};
      if (!isMultiLanguageSupported) {
        Object.entries(dataStructure).forEach(([key, field]) => {
          if (field.required && (!data[key] || data[key].toString().trim() === "")) {
            newErrors[key] = `${field.label} is required`;
          }
        });
      } else {
        supportedLanguage.forEach((language) => {
          const translation = data.translations[language];
          if (translation) {
            Object.entries(dataStructure).forEach(([key, field]) => {
              if (
                field.required &&
                (!translation[key] || translation[key].toString().trim() === "")
              ) {
                newErrors[`${language}-${key}`] = `${field.label} is required in ${LANGUAGE_LABELS[language]}`;
              }
            });
          }
        });
      }
      return newErrors;
    }, [data, dataStructure, isMultiLanguageSupported, supportedLanguage]);
  
    const handleSave = async () => {
      const newErrors = validateFields();
      if (Object.keys(newErrors).length > 0) {
        setAlert({
          title: "Validation Error",
          description: Object.values(newErrors).join("\n"),
          status: "error",
        });
        return;
      }
      setLoading(true);
      setAlert(null);
  
      try {
        onSave(data);
        setAlert({
          title: "Success",
          description: "Information updated successfully.",
          status: "success",
        });
      } catch (error) {
        setAlert({
          title: "Error",
          description: error.message,
          status: "error",
        });
      } finally {
        setLoading(false);
      }
    };
  
    const renderImageField = (language = null) => {
      const imageUrl = isMultiLanguageSupported
        ? data.translations?.[language]?.image_url
        : data.image_url;
  
      return (
        <FormControl mb={4}>
          <FormLabel>Image</FormLabel>
          {imageUrl && (
            <Box position="relative" display="inline-block" mb={2}>
              <Image
                src={imageUrl}
                alt="Uploaded"
                maxHeight="150px"
                onClick={() => {
                  setPreviewImage(imageUrl);
                  setIsImagePreviewOpen(true);
                }}
                style={{ cursor: "pointer" }}
              />
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => handleImageDelete(language)}
                position="absolute"
                top="3px"
                right="3px"
                size="sm"
                aria-label="Delete Image"
                color="gray.500"
                bg="gray.200"
                borderRadius="full"
                opacity="0.8"
                _hover={{ bg: "gray.300", opacity: 1 }}
                _active={{ bg: "gray.400" }}
              />
            </Box>
          )}
          <FormFields.ImageField
            value={imageUrl || ""}
            onChange={(url) => handleImageUpload(url, language)}
            isMultiLanguageSupported={isMultiLanguageSupported}
            language={language}
          />
        </FormControl>
      );
    };
  
    const renderField = (key, fieldType, language = null) => {
      const { type, label, options, ...rest } = fieldType;
      const value = isMultiLanguageSupported
        ? data.translations?.[language]?.[key]
        : data[key];
  
      const commonProps = {
        key: language ? `${language}-${key}` : key,
        label,
        value: String(value || ""),
        onChange: (value) => handleChange(key, value, language),
        ...rest,
      };
  
      switch (type) {
        case "rich-text":
          return (
            <Box key={key} mb={4}>
              <Text fontWeight={500} mb={2}>
                {label}
              </Text>
              <SunEditor
                setOptions={{
                  height: 150,
                  zIndex: -9999,
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    "/",
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link", "image", "video", "audio"],
                    ["fullScreen", "showBlocks", "codeView"],
                    ["preview", "print"],
                  ],
                }}
                setContents={String(value || "")}
                onChange={(newContent) => handleChange(key, newContent, language)}
              />
            </Box>
          );
        case "text":
          return <FormFields.TextField {...commonProps} />;
        case "textarea":
          return <FormFields.TextareaField {...commonProps} />;
        case "date":
          return <FormFields.DateField {...commonProps} />;
        case "dateTime":
          return <FormFields.DateTimeField {...commonProps} />;
        case "image":
          return renderImageField(language);
        case "inputNum":
          return <FormFields.InputNumberField {...commonProps} />;
        case "select":
          return <FormFields.SelectField {...commonProps} options={options} />;
        case "switch":
          return <FormFields.SwitchField {...commonProps} />;
        case "number":
          return <FormFields.NumberField {...commonProps} />;
        case "radio":
          return <FormFields.RadioField {...commonProps} options={options} />;
        case "color":
          return <FormFields.ColorField {...commonProps} />;
        case "checkbox":
          return <FormFields.CheckboxField {...commonProps} options={options} />;
        default:
          return null;
      }
    };
  
    return (
      <Box p="20px" borderWidth="1px" borderRadius="lg" mb="20px" boxShadow="md">
        {alert && (
          <Alert status={alert.status} mb={4}>
            <AlertIcon />
            <Box>
              <Box fontWeight="bold">{alert.title}</Box>
              <Box>{alert.description}</Box>
            </Box>
          </Alert>
        )}
  
        {/* {isMultiLanguageSupported ? (
          <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
            <TabList>
              {supportedLanguage.map((lang) => (
                <Tab key={lang}>{LANGUAGE_LABELS[lang]}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {supportedLanguage.map((language) => (
                <TabPanel key={language}>
                  {Object.entries(dataStructure).map(([key, field]) =>
                    renderField(key, field, language)
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          Object.entries(dataStructure).map(([key, field]) => renderField(key, field))
        )} */}
  {Object.entries(dataStructure).map(([key, field]) => renderField(key, field))}
        <Box mt="4" textAlign="right">
          <Button colorScheme="blue" onClick={handleSave} isLoading={loading}>
            Save
          </Button>
        </Box>
      </Box>
    );
  };
  
  export default InlineEditBox;