import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
// Chakra imports
import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon, Image,
    Input,
    InputGroup,
    InputRightElement,
    Text, useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// Assets
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import AuthService from "../../../axios/service/AuthService";
import banner from "../../../assets/img/auth/DS_logo_color.png";
import blackBanner from "../../../assets/img/auth/DS_logo_color_dark.png";
import {IoMdMoon, IoMdSunny} from "react-icons/io";
import { get, post, put, del } from '../../../api/request';

import JSEncrypt from 'jsencrypt';

// Add this constant for the public key
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDgnRswswMMMQcb
Fdc6upa0wnsMDBe4TYfJLuVsETXWWzczYTbyrTgixLtZNmYIQT1WJkc9g0FPlrf5
+NEbG48za/4qsKw46SUbDkFm3OBPafOOMWdKPHQ8nDUNhOomzOpDnuewsCMFt3Pi
p+wtwPtW3yWoC7+ZgyqN7RkjAO0g4i4XxkztS+jwjdXQ7rNWEmu/kZu8CNtL3jyN
bn2HAZpgSwBCo4raa1JZ6tm7/PDGvSD9RGYvNpy4HXLfad+Czr3G7cgSGzGIj6UQ
G8CgJvqrkD4SIBRxdM5VEyji1uvF9LPMv0V0eqEfc+f9CVdBSE195/nud8fT20wg
1CALEQ8JAgMBAAECggEAB/ivaLmrraS2S7jpVeteEp111DpAI8F1pJdSW1TbiCdd
1MgaFP/+hmZcv0hp09hr4rkyTU2xlU9kKV4SHsAKQ6ENKMrzG8RzdYu6mLnVSKA6
HXVlpOnzFp0VGW0aicS7OinjL1866PanKVe8Dy4ihWVfPuDm3H3+FkIgQqCjaceo
JBZSNJa2rAi5BnYC14tM9zyAU+hL+3jAqT9Bc0UD8WCx7MQ6YcC85vaapyYIAgw0
/K6F1Vx1eIBLWGS2Iw3R86Tlzc2E5Z4mHZv5KM3bjbj9mWhk0a3E/DYF2M25rZIW
UfMVzvNlW6DE/8vrmYSKULo3cQFFijpvZZmfGTx9kQKBgQD2MNlbmGqy/ohgitgn
IoBZTxPmi52+oD+bhfsGUyCIHhR0xwSAGax6KSiiNjMo6RqereYsgQaHqOMdavbM
Yxhj255NHPWI4JsnAMtTyO6b1hlrhnrZ7NLr0k1l7UnN5RfwWusmCcQLDcxH8ZBH
YP+xTq8ohVvpk3WuwIZPVeEg+QKBgQDpkCuNRxNKJI2hgAisas7eY0ln0p1Xf6nl
a+VAlF6iI/wYDihOPUIi47Krx5Zj4DKb97oS5Rem/26FpKb3sC3FuBddtoZ0JzpK
N8DwLI+SfL76A+9HMfoLIJs9/aynFZ+lw5czRBzRQR7aokzCEPHo/rFrCdnIRA8y
90V4OvLykQKBgQD03GsjdfoR46WC2DXa3q7npQY3FIAlnD8HkX+1r7I4kCpqZ5Or
UV3B9vPqOpwxwX9veajodMAo56pUlRG5AYq9ry7Ipdf0duj6JwkUYVIpfw2za4L/
A1RZpsbCbPsuJQDlV5hwMeBh2ixnFfUZeXl3QbpnRBTBwZ+X2vb7K4CKCQKBgQCC
vi+DADxuDpQxyYgRbCCtIIsg0Sc+qbojMQi949Atl1Mw7Avn8CarfSaxPiKTehca
c8v3c8KPuwDVUtwcXsTgnzKFRP1y29qnXRpl16vu5mHJdpZlFjbQL8p3Ix+Y0GPz
2M805XX0NS3eWWf6Yp3oPVp3fqYokuCnOP/sU6NX4QKBgEZ4XliV0ojrnVgLH5GB
S1OvARqg9gG3BH20/XUnuFWdsPoTHhMk8iB0nYcg+BSjikT6tFFSAbvOaNLvtNfI
wQHkEF+zdpqX72aWOAwNcvG+QCuSRF3pU1d0IAlKN96X14dJKWd0+eSaA7C9poPl
XnDQT5DDKgVrlhX6ohJjU3JZ
-----END PUBLIC KEY-----`;

let showBanner = banner
function SignIn() {
    const history = useHistory()
    useEffect(() => {
    },[])

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleText = useColorModeValue("navy.700", "white");
    const googleHover = useColorModeValue(
        {bg: "gray.200"},
        {bg: "whiteAlpha.300"}
    );
    const googleActive = useColorModeValue(
        {bg: "secondaryGray.300"},
        {bg: "whiteAlpha.200"}
    );
    const [show, setShow] = React.useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const cancelRef = React.useRef()
    const [invalidAuth, setInvalidAuth] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('Invalid Email or Password!');

    const invalidAuthDialogOnClose = () => {
        setInvalidAuth(true);
    };

    const validAuthDialogOnClose = () => {
        setInvalidAuth(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const encryptPassword = (password) => {
        try {
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(PUBLIC_KEY);
            return encrypt.encrypt(password);
        } catch (error) {
            console.error('Error encrypting password:', error);
            throw new Error('Failed to encrypt password');
        }
    };

    const handleClick = () => setShow(!show);

    const checkPassword = () => {
        setLoginLoading(true);
        const loginUser = {
            email: email,
            password: password
        };

        const encryptedPassword = encryptPassword(password);
        if (!encryptedPassword) {
            setLoginLoading(false);
            setErrorMessage('Encryption failed. Please try again.');
            setInvalidAuth(true);
            return;
        }

        const loginAPI = {
            email: email,
            password: encryptedPassword
        };

        post('cms_login', loginAPI)
            .then(response => {
                // if (response.error) {
                //     console.log('response.error')
                //     console.log(response.error.message)
                //     throw new Error(response.message || 'API login failed');
                // }
                console.log('response',response)
                return AuthService.signIn(loginUser);
            })
            .then(response => {
                if (response.error) {
                    throw new Error(response.message || 'Authentication failed');
                }
                
                if (response.data) {
                    const session = response.data.session;
                    const email = response.data.user.email;
                    const userId = response.data.user.id;
                    localStorage.setItem('authenticated', session.access_token);
                    localStorage.setItem('username', email);
                    localStorage.setItem('userId', userId);
                    history.push("/admin/dashboard");
                } else {
                    throw new Error('Invalid response data');
                }
            })
            .catch(e => {
                const message = e.response?.data?.error?.message || 'Invalid Email or Password!';
                setErrorMessage(message);
                setInvalidAuth(true);
            })
            .finally(() => {
                setLoginLoading(false);
            });            
    };

    return (
        <Flex position='relative' h='max-content'>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginTop='10vh'>
                <Flex
                    maxW={{base: "100%", md: "max-content"}}
                    w='100%'
                    mx={{base: "auto", lg: "0px"}}
                    me='auto'
                    h='100%'
                    alignItems='start'
                    justifyContent='center'
                    mb={{base: "30px", md: "60px"}}
                    px={{base: "25px", md: "0px"}}
                    mt={{base: "40px", md: "14vh"}}
                    flexDirection='column'>
                    <Box>
                        <Heading color={textColor} fontSize='36px' mb='10px'>
                            Sign In
                        </Heading>
                        <Text
                            mb='36px'
                            ms='4px'
                            color={textColorSecondary}
                            fontWeight='400'
                            fontSize='md'>
                            Enter your email and password to sign in!
                        </Text>
                    </Box>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{base: "100%", md: "420px"}}
                        maxW='100%'
                        background='transparent'
                        borderRadius='15px'
                        mx={{base: "auto", lg: "unset"}}
                        me='auto'
                        mb={{base: "20px", md: "auto"}}>
                        <FormControl>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'>
                                Email<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant='auth'
                                fontSize='sm'
                                ms={{base: "0px", md: "0px"}}
                                type='email'
                                placeholder='email'
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                display='flex'>
                                Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    isRequired={true}
                                    fontSize='sm'
                                    placeholder='Password'
                                    mb='24px'
                                    size='lg'
                                    type={show ? "text" : "password"}
                                    variant='auth'
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{cursor: "pointer"}}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <Flex justifyContent='space-between' align='center' mb='24px'>
                            </Flex>
                            <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                isLoading={loginLoading}
                                onClick={() => checkPassword()}
                            >
                                Sign In
                            </Button>
                        </FormControl>
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="30vh">
                            <Image src={showBanner} w='200px' h='150px' ></Image>
                        </Box>
                    </Flex>
                </Flex>
            </Box>
            <AlertDialog
                isOpen={invalidAuth}
                leastDestructiveRef={cancelRef}
                onClose={invalidAuthDialogOnClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {errorMessage}
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={validAuthDialogOnClose}>
                                OK
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <FixedPlugin />
        </Flex>
    );
}

function FixedPlugin(props) {
    const { ...rest } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
    const [index,setIndex] = useState(0)
    const images = [banner,blackBanner]
    const handleToggle = () => {
        toggleColorMode()
        const nextIndex = index === 0 ? 1 : 0
        setIndex(nextIndex)
        showBanner = images[nextIndex]
    }

    return (
        <Button
            {...rest}
            h='60px'
            w='60px'
            zIndex='99'
            bg={bgButton}
            position='fixed'
            variant='no-effects'
            left={document.documentElement.dir === "rtl" ? "35px" : ""}
            right={document.documentElement.dir === "rtl" ? "" : "35px"}
            bottom='30px'
            border='1px solid'
            borderColor='#6A53FF'
            borderRadius='50px'
            onClick={handleToggle}
            display='flex'
            p='0px'
            align='center'
            justify='center'>
            <Icon
                h='24px'
                w='24px'
                color='white'
                as={colorMode === "light" ? IoMdMoon : IoMdSunny}
            />
        </Button>
    );
}

export default SignIn;