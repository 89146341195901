// TutorialsPage.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  VStack,
  useColorModeValue,
  IconButton,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  HStack
} from '@chakra-ui/react';
import { RepeatIcon, AddIcon } from '@chakra-ui/icons';
import DataTablePagination from '../../../components/base/DataTablePagination';
import DataTable from '../../../components/base/DataTableTranslate';
import AddEditTutorialModal from './AddEditTutorialModal';
import DeleteTutorialAlert from '../../../components/base/AlertDialogComponent';
import { LANGUAGES } from './translations';
import { format } from 'date-fns';

// Table configuration object that defines column behavior and formatting
const createColumnMap = (currentLanguage) => ({
  // index: {
  //   label: '#',
  //   sortable: false,
  //   width: '5%',
  //   align: 'center',
  //   formatter: (_, index, pagination) => (pagination.currentPage - 1) * pagination.itemsPerPage + index + 1
  // },
  sort_order: {
    label: 'Order',
    sortField: 'sort_order',
    width: '10%', // Fixed width for sort order
    minWidth: '100px',
    align: 'center',
    formatter: (tutorial) => tutorial.sort_order,
    searchable: true
  },
  image_url: {
    label: 'Image',
    width: '15%',
    sortable: false,
    imageConfig: {
      height: '60px',
      ratio: '16:9'  // This will make width approximately 106.67px (60px * 16/9)
    },
    formatter: (tutorial) => {
      const translation = tutorial.translations.find(t => t.language === currentLanguage) ||
        tutorial.translations[0];
      return {
        url: translation?.image_url || '/placeholder-image.jpg',
        alt: ``
      };
    }
  },
  created_at: {  // Added new created_at column
    label: 'Created Time',
    sortField: 'created_at',
    width: 'auto',
    align: 'left',
    flex: 1,
    formatter: (tutorial) => tutorial.created_at
      ? format(new Date(tutorial.created_at), 'yyyy-MM-dd HH:mm')
      : '-'
  },
  updated_at: {
    label: 'Last Modified Time',
    sortField: 'updated_at',
    width: 'auto',
    maxWidth: 'auto',  // Explicitly set to auto
    align: 'left',
    flex: 1,
    formatter: (tutorial) => tutorial.updated_at
      ? format(new Date(tutorial.updated_at), 'yyyy-MM-dd HH:mm')
      : '-'
  },
  is_active: {
    label: 'Status',
    sortField: 'is_active',
    sortable: false,
    width: '10%',
    align: 'center',
    isStatus: true,
    formatter: (store) => ({
      value: true,
      label: 'Active',
      colorScheme: 'green'
    })
  }
});

const TutorialsPage = () => {
  // State management
  const [tutorials, setTutorials] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortConfig, setSortConfig] = useState({ field: 'sort_order', direction: 'asc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);

  // Modal controls
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();

  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose
  } = useDisclosure();

  // Memoized column map
  const columnMap = useMemo(() => createColumnMap(currentLanguage), [currentLanguage]);

  const sortOrderOptions = useMemo(() => {
    if (!tutorials.length) {
      return [1];
    }
  
    const existingOrders = tutorials.map(t => t.sort_order);
    
    if (selectedTutorial) {
      // For editing: return all available positions including current position
      return [...new Set(existingOrders)].sort((a, b) => a - b);
    } else {
      // For creating new: return all existing positions
      return [...new Set(existingOrders)].sort((a, b) => a - b);
    }
  }, [tutorials, selectedTutorial]);

  // Process data for table display
  const processDataForTable = useCallback((tutorialsData) => {
    return tutorialsData.map((tutorial, index) => {
      const processedData = {};
      Object.keys(columnMap).forEach(key => {
        const formattedValue = columnMap[key].formatter?.(
          tutorial,
          index,
          pagination,
          currentLanguage
        );
        processedData[key] = formattedValue;
      });
      processedData._id = tutorial.id;
      return processedData;
    });
  }, [columnMap, pagination, currentLanguage]);

  // Data fetching
  const fetchTutorials = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const options = {
        joins: [{
          table: 'tutorial_settings_translations',
          fields: '*'
        }],
        sortConfig,
        pagination
      };

      const { data } = await global.genericapi.fetchRecordsNew('tutorial_settings', options);
      const { data: total } = await global.genericapi.fetchRecordsNew('tutorial_settings');
      setTutorials(data);
      setTotalCount(total.length);
    } catch (error) {
      console.error('Error fetching tutorials:', error);
      setError(error.message || 'Failed to fetch tutorials');
    } finally {
      setIsLoading(false);
    }
  }, [sortConfig, pagination]);

  // Initial data fetch
  useEffect(() => {
    fetchTutorials();
  }, [fetchTutorials]);

  // Event handlers
  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = useCallback((item) => {
    const originalTutorial = tutorials.find(tutorial => tutorial.id === item._id);
    setSelectedTutorial(originalTutorial);
    onUpdateModalOpen();
  }, [onUpdateModalOpen, tutorials]);

  const handleDeleteClick = useCallback((item) => {
    const originalTutorial = tutorials.find(tutorial => tutorial.id === item._id);
    setSelectedTutorial(originalTutorial);
    onAlertDialogOpen();
  }, [onAlertDialogOpen, tutorials]);

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  // Theme values
  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Box bg={bgColor} p={[3, 4, 5]} width="100%">
        <VStack spacing={[3, 4, 5]} align="stretch">
          <Flex justifyContent="flex-end">
            <HStack spacing={4}>
              <IconButton variant='action' icon={<RepeatIcon />} aria-label="Refresh" onClick={fetchTutorials} />
              <IconButton variant='action' icon={<AddIcon />} aria-label="Add" onClick={onCreateModalOpen} />
            </HStack>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : (
            <DataTable
              data={processDataForTable(tutorials)}
              columnMap={columnMap}
              sortField={sortConfig.field}
              sortDirection={sortConfig.direction}
              onSort={handleSort}
              onEdit={handleUpdateClick}
              onDelete={handleDeleteClick}
              onRefresh={fetchTutorials}
            />
          )}

          {totalCount < pagination.itemsPerPage ? <></> : (
            <DataTablePagination
              currentPage={pagination.currentPage}
              totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={pagination.itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              itemsPerPageOptions={[5, 10, 20, 50]}
            />
          )}

        </VStack>
      </Box>

      <AddEditTutorialModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        onComplete={fetchTutorials}
        sortOrderOptions={sortOrderOptions}
      />

      <AddEditTutorialModal
        isOpen={isUpdateModalOpen}
        initialData={selectedTutorial}
        onClose={onUpdateModalClose}
        onComplete={fetchTutorials}
        sortOrderOptions={sortOrderOptions}
      />

      <DeleteTutorialAlert
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        data={{ 'tutorial_setting_id': parseInt(selectedTutorial?.id) }}
        onComplete={fetchTutorials}
        title="Delete Tutorial"
        message="Are you sure you want to delete this tutorial? This action cannot be undone."
        tableName='tutorial_settings'
      />
    </Box>
  );
};

export default TutorialsPage;