import React, { useState } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';
import { useLocation } from "react-router-dom"
import InlineEditBox from '../../components/base/InlineEditBox';
import {
  Box,
  Text,
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const NewsPage = () => {
  const [language, setLanguage] = useState('en');
  const tableName = 'users_profile';
  const primaryKey = 'id';
  const location = useLocation();

  const { item } = location.state || {};
 
  const languageOptions = {
    en: {
      keyMappings: {
        given_name: 'Given Name',
        surname: 'Surname',
        email: 'Email',
        phone: 'Phone',
        birthday: 'Birthday',
        gender: 'Gender',
        points: 'Points',
      },
      tableHeaders: ['given_name', 'surname', 'email', 'phone', 'birthday', 'gender', 'points'],
    },
    zh: {
      keyMappings: {
        given_name: '名',
        surname: '姓',
        email: '邮箱',
        phone: '电话',
        birthday: '生日',
        gender: '性别',
        points: '积分',
      },
      tableHeaders: ['given_name', 'surname', 'email', 'phone', 'birthday', 'gender', 'points'],
    },
    zh_tw: {
      keyMappings: {
        given_name: '名字',
        surname: '姓氏',
        email: '電子郵件',
        phone: '電話',
        birthday: '生日',
        gender: '性別',
        points: '積分',
      },
      tableHeaders: ['given_name', 'surname', 'email', 'phone', 'birthday', 'gender', 'points'],
    },
  };

  const keyMappings = {
    given_name: 'Given Name',
    surname: 'Surname',
    email: 'Email',
    phone: 'Phone',
    birthday: 'Birthday',
    gender: 'Gender',
    points: 'Points',
  };

  const tableHeaders = [
    'given_name',
    'surname',
    'email',
    'phone',
    'birthday',
    'gender',
    'points',
  ];
  const currentLanguageConfig = languageOptions[language];
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "8px" }}>
      <Tabs onChange={(index) => setLanguage(['en', 'zh', 'zh_tw'][index])} mb="20px">
        <TabList>
          <Tab>English</Tab>
          <Tab>Traditional Chinese</Tab>
          <Tab>Simplified Chinese</Tab>
        </TabList>
      </Tabs>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <InlineEditBox
          initialData={item}
          onSave={(updatedData) => console.log("Saved data:", updatedData)}
          dataStructure={{
            given_name: { type: "text", label: "Given Name", required: true },
            surname: { type: "text", label: "Surname", required: true },
            email: { type: "text", label: "Email", required: true },
            phone: { type: "text", label: "Phone", required: true },
          }}
          isEdit={true}
          isMultiLanguageSupported={true}
        />
      </Box>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <Text fontSize="xl" fontWeight="bold" ml={2} mb={-10}>Prize</Text>
        <BaseMemberActivityPageComponent
          txt="Member Info"
          addItemComponent={AddEditNewsModal}
          itemCardComponent={NewsCard}
          showToggleView={false}
          tableName={tableName}
          tableHeaders={tableHeaders}
          keyMappings={keyMappings}
          showPagination={true}
          showAddIcon={true}
          primaryKey={primaryKey}
          hideView={true}
        />
      </Box>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <Text fontSize="xl" fontWeight="bold" ml={2}  mb={3}>Game Record</Text>
        <BaseMemberActivityPageComponent
          txt="Member Info"
          addItemComponent={AddEditNewsModal}
          itemCardComponent={NewsCard}
          showToggleView={false}
          tableName={tableName}
          tableHeaders={tableHeaders}
          keyMappings={keyMappings}
          showPagination={true}
          showSearch={true}
          searchPlaceholder="Search by ID/Member ID"
          searchFields="email"
          showAddIcon={false}
          primaryKey={primaryKey}
          showActions={false}
        />
      </Box>
    </Box>

  );
};

export default NewsPage;

