import { supabase } from "../../api/supabase";

const AuthService = {

    async signIn(body) {
        const res = await supabase.auth.signInWithPassword({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async signIn(body) {
        const res = await supabase.auth.signInWithPassword({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async signOut(body) {
        const res = await supabase.auth.signOut()
        return res
    },

    async signUp(body) {
        const res = await supabase.auth.signUp({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async getUser() {
        const res = await supabase.auth.getUser()
        return res
    },
}
export default AuthService