import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import { post } from '../../../../api/request';

const AlertDialogComponent = ({ isOpen, onClose, onComplete, coupon, title, message }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cancelRef = React.useRef();

  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const couponData = {
        id: coupon?.id,
        operator_user_id: localStorage.getItem('userId')
      };

      const endpoint = `/delete_coupon`;
      const response = await post(endpoint, couponData);

      // console.log('response',response);
      
      if (response.data?.success) {
        handleClose();
        onComplete();
      } else {
        throw new Error('Failed to delete coupon');
      }
    } catch (err) {
      console.error('Error deleting coupon:', err);
      setError(err.message || 'Failed to delete coupon');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsLoading(false);
    setError(null);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={4}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {message}
            {error && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button 
              ref={cancelRef} 
              onClick={handleClose}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
              isLoading={isLoading}
              loadingText="Deleting..."
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogComponent;