// CouponsPage.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  IconButton,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  HStack
} from '@chakra-ui/react';
import { SearchIcon, RepeatIcon, AddIcon } from '@chakra-ui/icons';
import DataTablePagination from '../../components/base/DataTablePagination';
import DataTable from '../../components/base/DataTableTranslate';
import AddEditCouponModal from './AddEditCouponModal';
import DeleteCouponAlert from './AlertDialogComponent';
import { LANGUAGES } from './translations';
import { format } from 'date-fns';
import { get, post } from '../../../../api/request';

// Table configuration object that defines column behavior and formatting
const createColumnMap = (currentLanguage) => ({
  index: {
    label: '#',
    sortable: false,
    width: '5%',
    align: 'center',
    formatter: (_, index, pagination) => (pagination.currentPage - 1) * pagination.itemsPerPage + index + 1
  },
  image_url: {
    label: 'Image',
    sortable: false,
    width: '10%',
    isImage: true,
    align: 'center',
    formatter: (coupon) => {
      const translation = coupon.translations.find(t => t.language === currentLanguage) || coupon.translations[0];
      return {
        url: translation?.image_url || '/placeholder-image.jpg',
        alt: ''
      };
    }
  },
  caption: {
    label: 'Caption',
    sortField: 'coupons_translation.caption',
    sortable: false,
    width: '15%',
    minWidth: '200px',
    align: 'center',
    formatter: (coupon) => {
      const translation = coupon.translations.find(t => t.language === currentLanguage) || coupon.translations[0];
      return translation?.caption || '-';
    },
    searchable: true
  },
  detail: {
    label: 'Detail',
    sortField: 'coupons_translation.detail',
    sortable: false,
    width: '20%',
    minWidth: '200px',
    align: 'center',
    formatter: (coupon) => {
      const translation = coupon.translations.find(t => t.language === currentLanguage) || coupon.translations[0];
      return translation?.detail || '-';
    },
    searchable: true
  },
  points_required: {
    label: 'Points',
    sortField: 'points_required',
    sortable: false,
    width: '10%',
    minWidth: '150px',
    align: 'center'
  },
  remaining_quantity: {
    label: 'Remain',
    sortField: 'remaining_quantity',
    sortable: false,
    width: '8%',
    minWidth: '150px',
    align: 'center'
  },
  effective_period: {
    label: 'Effective Period',
    sortable: false,
    width: '15%',
    minWidth: '300px',
    align: 'center',
    formatter: (coupon) => {
      const startDate = format(new Date(coupon.effective_start_date), 'yyyy-MM-dd');
      const endDate = format(new Date(coupon.effective_end_date), 'yyyy-MM-dd');
      return `${startDate} ~ ${endDate}`;
    }
  },
  redemption_period: {
    label: 'Redemption Period',
    sortable: false,
    width: '15%',
    minWidth: '300px',
    align: 'center',
    formatter: (coupon) => {
      const startDate = format(new Date(coupon.redemption_start_date), 'yyyy-MM-dd');
      const endDate = format(new Date(coupon.redemption_end_date), 'yyyy-MM-dd');
      return `${startDate} ~ ${endDate}`;
    }
  }
});

const CouponsPage = () => {
  // State management
  const [coupons, setCoupons] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });
  const [sortConfig, setSortConfig] = useState({ field: 'id', direction: 'desc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);

  // Modal controls
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();

  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose
  } = useDisclosure();

  // Memoized column map
  const columnMap = useMemo(() => createColumnMap(currentLanguage), [currentLanguage]);

  // Process data for table display
  const processDataForTable = useCallback((couponsData) => {
    return couponsData.map((coupon, index) => {
      const processedData = {};
      Object.keys(columnMap).forEach(key => {
        const formattedValue = columnMap[key].formatter?.(
          coupon,
          index,
          pagination,
          currentLanguage
        ) ?? coupon[key];
        processedData[key] = formattedValue;
      });
      processedData._id = coupon.id;
      return processedData;
    });
  }, [columnMap, pagination, currentLanguage]);

  // Data fetching
  // const fetchCoupons = useCallback(async () => {
  //   setIsLoading(true);
  //   setError(null);
  //   try {
  //     // Mock API call - replace with actual API implementation
  //     const response = await global.genericapi.fetchRecordsNew('coupons', {
  //       joins: [{
  //         table: 'coupons_translation',
  //         fields: '*'
  //       }],
  //       filterConfig,
  //       sortConfig,
  //       pagination
  //     });
      
  //     setCoupons(response.data);
  //     setTotalCount(response.data.length);
  //   } catch (error) {
  //     console.error('Error fetching coupons:', error);
  //     setError(error.message || 'Failed to fetch coupons');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, [filterConfig, sortConfig, pagination]);

  const fetchCoupons = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const options = {
        filterConfig,
        sortConfig,
        pagination
      };
      // console.log('response', response.data);
      // const { data } = await global.genericapi.fetchRecordsNew('push_notifications', options);
      // const { data: total } = await global.genericapi.fetchRecordsNew('push_notifications');
      // setNotifications(data);
      // setTotalCount(data.length);
      const params = {
        'page_number': pagination.currentPage,
        'page_size': pagination.itemsPerPage
      }
      const response = await get('coupon_list', params);
      if (response.data) {
        // Sort the data by id in descending order
        const sortedData = response.data.sort((a, b) => a.id - b.id);
        setCoupons(sortedData);
        setTotalCount(response.data.total || 0);

        console.log('response', response.data);
      } else {
        throw new Error(response.data?.message);
      }

    } catch (error) {
      console.error('Error fetching coupons:', error);
      setError(error.message);
      // setError(error.message || i18n.t('fetchError'));
    } finally {
      setIsLoading(false);
    }
  }, [filterConfig, sortConfig, pagination]);

  // Initial data fetch
  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  // Event handlers
  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = useCallback((item) => {
    const originalCoupon = coupons.find(coupon => coupon.id === item._id);
    setSelectedCoupon(originalCoupon);
    onUpdateModalOpen();
  }, [onUpdateModalOpen, coupons]);

  const handleDeleteClick = useCallback((item) => {
    const originalCoupon = coupons.find(coupon => coupon.id === item._id);
    setSelectedCoupon(originalCoupon);
    onAlertDialogOpen();
  }, [onAlertDialogOpen, coupons]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  // Theme values
  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Box bg={bgColor} p={[3, 4, 5]} width="100%">
        <VStack spacing={[3, 4, 5]} align="stretch">
          <Flex
            direction={["column", "row"]}
            justifyContent="space-between"
            alignItems={["flex-start", "center"]}
            wrap="wrap"
            gap={4}
          >
            <InputGroup maxW={["full", "300px"]}>
              {/* <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search coupons..."
                value={searchText}
                onChange={handleSearchChange}
                borderRadius="full"
              /> */}
            </InputGroup>

            <HStack spacing={4} mt={[0, 0]} wrap="wrap">
              <IconButton 
                variant='action' 
                ml="auto" 
                icon={<RepeatIcon />} 
                aria-label="Refresh" 
                onClick={fetchCoupons} 
              />
              <IconButton
                variant='action'
                ml="auto"
                icon={<AddIcon />}
                aria-label="Add"
                onClick={onCreateModalOpen}
              />
            </HStack>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : (
            <DataTable
              data={processDataForTable(coupons)}
              columnMap={columnMap}
              sortField={sortConfig.field}
              sortDirection={sortConfig.direction}
              onSort={handleSort}
              onEdit={handleUpdateClick}
              onDelete={handleDeleteClick}
              onRefresh={fetchCoupons}
            />
          )}

          {totalCount > pagination.itemsPerPage && (
            <DataTablePagination
              currentPage={pagination.currentPage}
              totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={pagination.itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              itemsPerPageOptions={[5, 10, 20, 50]}
            />
          )}
        </VStack>
      </Box>

      {/* Add/Edit Modal Component */}
      <AddEditCouponModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        onComplete={fetchCoupons}
      />

      <AddEditCouponModal
        isOpen={isUpdateModalOpen}
        initialData={selectedCoupon}
        onClose={onUpdateModalClose}
        onComplete={fetchCoupons}
      />

      {/* Delete Alert Component */}
      <DeleteCouponAlert
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        onComplete={fetchCoupons}
        coupon={selectedCoupon}
        title="Delete Coupon"
        message="Are you sure you want to delete this coupon? This action cannot be undone."
      />
    </Box>
  );
};

export default CouponsPage;