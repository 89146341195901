import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Image, Switch, HStack, Tooltip, Flex } from '@chakra-ui/react';
import { ViewIcon, EditIcon, DeleteIcon, StarIcon, CheckIcon, CloseIcon,TriangleUpIcon, TriangleDownIcon, SmallAddIcon} from '@chakra-ui/icons';
import { useState } from 'react';
import { SlArrowUp,SlArrowDown} from "react-icons/sl";
import Pagination from './Pagination';
import { reduceHooks } from 'react-table';
// const handleToggleActive = (key,item) => {

// }

const formatDate = (value) => {
  //   const date = new Date(value);
  //   if (!isNaN(date.getTime())) {
  //     return date.toISOString().split('T')[0].replace(/-/g, '/');
  //   }
  // return value;
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;

};

const CustomTable = ({
  data,
  tableHeaders,
  sort,
  handleSortChange,
  handleView,
  handleEdit,
  handleDelete,
  handleFav,
  handleEnableDisable,
  handleToggleActive,
  keyMappings = {}, // Key mappings for display names
  columns = [],
  isEditDisabled, // New prop
  isDeleteDisabled,
  currentPage, // Accept currentPage as a prop
  itemsPerPage, // Accept
  showActions = true,
  handleSort, // 接收 handleSort
  sortConfig, // 接收 sortConfig
  sortableHeader,
  // itemsPerPage = 10, 
}) => {

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const renderTh = (key) => {
    const keys = key.split('.');
    var name = (keyMappings[key] || key)
    if (keys.length > 1) {
      const last_key = keys[keys.length - 1]
      var name = (keyMappings[last_key] || last_key)
    }
    return  capitalizeFirstLetter(name);
    
  };

  const renderCellValue = (value) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return JSON.stringify(value);
    }
    return value;
  };

  const renderCellKey = (key, item) => {

    const keys = key.split('.');
    var key_name = key
    let value = item[key_name];

    if (key_name === 'mandatory_update_version') {
      return renderCellValue(value);
    }

    if (keys.length === 3) {

      // console.log('keys',keys)
      let indexTable = keys[0]
      if (item[indexTable].length > 0) {
        let index = keys[1]
        let indexKey = keys[2]
        value = item[indexTable][index][indexKey]
      }

    } else if (keys.length === 2) {

      let indexTable = keys[0]
      let indexKey = keys[1]
      // console.log('keys',item[indexTable])
      value = item[indexTable][indexKey]

    }

    if (value === null || value === undefined) return '';
    if (/image_url|image/i.test(key)) {
      // console.log('item[key]', key)
      return (
        <Box maxWidth="100px">
          <Image src={value} objectFit="contain" alt=" " />
        </Box>
      );
    } else if (/card_face/i.test(key)) {
      return (
        <Box maxWidth="80px" mt={3}>
          <Image src={value} objectFit="contain" alt=" " />
        </Box>
      );

    } else if (key === 'is_active') {
      return (
        <Switch
          isChecked={item[key]}
          onChange={() => handleToggleActive(item)}
          size="sm"
        />
      )
    } else if (['created_at', 'updated_at', 'sent_at', 'scheduled_at'].indexOf(key_name) > -1) {
      return (renderCellValue(formatDate(value)))
    } else if (key_name.includes('date')) {
      return (renderCellValue(formatDate(value)))
    } else if (key_name.includes('tier')) {
      value = value?.charAt(0).toUpperCase() + value?.slice(1)
      return (renderCellValue(value))
    } else if (key_name.includes('language')) {
      if (value === 'zh-Hant') {
        value = 'Traditional Chinese'
      } else if (value === 'zh-Hans') {
        value = 'Simplified Chinese'
      } else {
        value = 'English'
      }
      // console.log('key',key,item[key])
      return (renderCellValue(value))
    } else if (key_name.includes('platform')) {
      if (value === 'ios') {
        value = 'iOS'
      } else if (value === 'android') {
        value = 'Android'
      }
      // console.log('key',key,item[key])
      return (renderCellValue(value))
    } else if (key_name.includes('_color')) {
      return <Box height="20px" width="20px" bg={value} borderRadius="sm" />;
    } else {
      return (renderCellValue(value))
    }

  };

  const renderCellContent = (key, item) => {
    const value = renderCellKey(key, item);
    if (key === 'detail') { 
      const minWidth = value.length <= 10 ? '80px' : '120px';
      const maxWidth = '200px';
      return (
        <Box
        minWidth={minWidth}
        maxWidth={maxWidth}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="normal"
          display="-webkit-box"
          style={{
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </Box>
      );
    }
    if (typeof value === 'string') {
      const minWidth = value.length <= 10 ? '80px' : '120px';
      const maxWidth = '200px';

      const content = (
        <Box
          minWidth={minWidth}
          maxWidth={maxWidth}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {value}
        </Box>
      );

      if (value.length > 10) {
        // console.log('value', value.length)
        return (
          <Tooltip label={value}>
            <Box
              maxWidth="300px"
              minWidth="120px"
              maxHeight="200px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="normal"
              display="-webkit-box"
              style={{
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      }

      return content;
    }

    return value;
  };

  return (
    <Box overflowX="auto"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="md"
      borderRadius="md"
      mb={8}
    > 
      <Table variant="simple" style={{ tableLayout: 'auto' }}>
        <Thead>
          <Tr>
            <Th textTransform="none" fontSize="md" fontWeight="bold">#</Th>
            {(tableHeaders.length > 0 ? tableHeaders : Object.keys(data[0] || {})).map((key) => (
              <Th key={key} onClick={() => handleSort(key)} textTransform='none' fontSize="md" whiteSpace="nowrap" borderBottom="2px solid" py={7} fontWeight="bold">
                 <Flex alignItems="center">{renderTh(key)} {sortConfig?.field === key ? (
                sortConfig.direction === 'asc' ? <Box ml={2}> <SlArrowUp  size={1} /></Box> : <Box ml={2}><SlArrowDown  size={10}/></Box>
              ) : (
                // 只在特定的 header 上显示模糊图标
                sortableHeader === key && (
                  <Box ml={2}>
                    <SlArrowUp size={10} />
                  </Box>

                )
              )}</Flex></Th>))}
            {showActions && <Th textTransform='none' fontSize="md">Actions</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data.length > 0 ? (
            data.map((item, rowIndex) => (
              <Tr key={rowIndex} >
                <Td style={{ whiteSpace: 'normal' }}>{(currentPage && itemsPerPage) ? ((currentPage - 1) * itemsPerPage + rowIndex + 1) : (rowIndex + 1)}</Td> {/* Index Column */}
                {(tableHeaders.length > 0 ? tableHeaders : Object.keys(item)).map((key) => (
                  <Td key={key} style={{ whiteSpace: 'normal', overflowWrap: "break-word", wordBreak: "break-word" }}>
                    {renderCellContent(key, item)}
                  </Td>
                ))}
                {showActions && (
                  <Td>
                    <HStack>
                      <IconButton
                        onClick={() => handleEdit(item)}
                        icon={<EditIcon />}
                        colorScheme="blue"
                        aria-label="Edit"
                        size="sm"
                        isDisabled={isEditDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                      <IconButton
                        onClick={() => handleDelete(item)}
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        aria-label="Delete"
                        size="sm"
                        isDisabled={isDeleteDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                    </HStack>
                  </Td>
                )}
              </Tr>
            ))
          ):(
            <Tr>
              <Td colSpan={(tableHeaders.length > 0 ? tableHeaders.length : Object.keys(data[0] || {}).length) + 1}>No data available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomTable;
