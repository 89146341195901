import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card";
import  ActivityListPage from "./activityListPage";
export default function Settings() {
    return (
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <Card
                    direction='column'
                    w='100%'
                    px='20px'
                    overflowX={{ sm: "scroll", lg: "hidden" }}>
                    <ActivityListPage></ActivityListPage>
                </Card>
            </Box>
    );
}
